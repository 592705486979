/*
 * @license ////////////////////////////////////////////////////////////////////
 * @license // Copyright 2022-2024 MeVis Medical Solutions AG  all rights reserved //
 * @license ////////////////////////////////////////////////////////////////////
 */

import { Close as CloseIcon } from '@mui/icons-material'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type { ReactNode } from 'react'
import React from 'react'
import { Form } from 'react-final-form'
import ScrollBar from 'react-perfect-scrollbar'

import { Button } from 'components/materialUIWrappers'

const useStyles = makeStyles(theme => ({
    title: {
        margin: 0,
        padding: theme.spacing(2),
        paddingTop: theme.spacing(4)
    },
    content: {
        padding: `${theme.spacing(2)} !important`
    },
    scrollableContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        maxHeight: '50vh',
        marginRight: theme.spacing(2)
    },
    actionButton: {
        minWidth: theme.spacing(11)
    },
    spacingDialogActions: {
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        opacity: 0.5
    }
}))

export function TitleArea({
    onReject,
    onSubmit,
    title,
    subtitle
}: {
    onReject?: (...args: any) => any
    onSubmit?: (...args: any) => any
    title: ReactNode
    subtitle?: ReactNode
}) {
    const classes = useStyles()

    return (
        <React.Fragment>
            <IconButton
                aria-label="close"
                onClick={onReject || onSubmit}
                className={classes.closeButton}
                data-testid="close"
                size="large"
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle className={classes.title} component="div">
                <Typography component="h1" variant="h2">
                    {title}
                </Typography>
                {subtitle && (
                    <Typography component="div" variant="h5">
                        {subtitle}
                    </Typography>
                )}
            </DialogTitle>
        </React.Fragment>
    )
}

export function ContentArea({ contentText, children }: { contentText?: string; children?: React.ReactNode }) {
    const classes = useStyles()

    return (
        <DialogContent className={classes.content} dividers>
            <ScrollBar options={{ suppressScrollX: true }}>
                <Box className={classes.scrollableContent}>
                    {contentText && (
                        <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                            {contentText}
                        </Typography>
                    )}
                    {children}
                </Box>
            </ScrollBar>
        </DialogContent>
    )
}

export function ActionsArea({
    onReject,
    onSubmit,
    submitting,
    submitDisabled,
    rejectText,
    submitText,
    variant
}: {
    onReject?: (...args: any) => any
    onSubmit?: (...args: any) => any
    submitting?: boolean
    submitDisabled?: boolean
    rejectText?: string
    submitText?: string
    variant?: 'danger' | 'inherit' | 'primary' | 'secondary'
}) {
    const classes = useStyles()

    return (
        <DialogActions classes={{ spacing: classes.spacingDialogActions }}>
            {onReject && (
                <Button
                    variant={onSubmit ? 'outlined' : 'contained'}
                    className={classes.actionButton}
                    onClick={onReject || onSubmit}
                    data-testid="reject"
                >
                    {rejectText}
                </Button>
            )}
            {onSubmit && (
                <Button
                    className={classes.actionButton}
                    color={variant}
                    disabled={submitting || submitDisabled}
                    type="submit"
                    role="button"
                    data-testid="submit"
                >
                    {submitText}
                </Button>
            )}
        </DialogActions>
    )
}

export default function FormModal({
    title,
    subtitle = '',
    contentText = '',
    rejectText = gettext('Cancel'),
    submitText = gettext('Save'),
    submitDisabled = false,
    children,
    maxWidth = 'sm',
    open,
    onEnter,
    onSubmit,
    onReject,
    variant = 'primary',
    initialValues,
    validate,
    autoComplete = 'on'
}: {
    title: string
    subtitle?: string | JSX.Element
    contentText?: string
    rejectText?: string
    submitText?: string
    submitDisabled?: boolean
    children?: React.ReactNode
    maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    open: boolean
    onEnter?: (...args: any) => any
    onSubmit: (...args: any) => any
    onReject?: (...args: any) => any
    variant?: 'danger' | 'inherit' | 'primary' | 'secondary'
    initialValues?: object
    validate?: (...args: any) => any
    autoComplete?: string
}) {
    return (
        <Dialog
            open={open}
            onClose={onReject || onSubmit}
            fullWidth
            maxWidth={maxWidth}
            data-testid="form-modal"
            TransitionProps={{
                onEnter
            }}
        >
            <TitleArea title={title} subtitle={subtitle} onSubmit={onSubmit} onReject={onReject} />
            <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting }) => (
                    <form noValidate onSubmit={handleSubmit} style={{ margin: 0 }} autoComplete={autoComplete}>
                        <ContentArea contentText={contentText}>{children}</ContentArea>
                        <ActionsArea
                            onReject={onReject}
                            onSubmit={onSubmit}
                            submitting={submitting}
                            submitDisabled={submitDisabled}
                            rejectText={rejectText}
                            submitText={submitText}
                            variant={variant}
                        />
                    </form>
                )}
                validate={validate}
            />
        </Dialog>
    )
}
